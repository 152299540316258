import { runNumber } from './common.js'

function isTypeNull(val){ //判断数据类型
	return !val && val !== 0 && val !== '0' && typeof val !== 'number';
}

export default function offerProfitCompute(row,type,numKye){ //报价利润计算
	if(
		(
			isTypeNull(row.salesDis) &&
			//isTypeNull(row.purchaseDis) && 
			isTypeNull(row.profitSite) && 
			isTypeNull(row.guideSite) && 
			isTypeNull(row.salesPrice)
		) || isTypeNull(row.purchasePrice)
	){
		if(type === 'purchasePrice'){ //采购价
			row.purchaseDis = (runNumber(runNumber(row[type]) / runNumber(row.guidePrice)) * 100).toFixed(2);
		}else if(type === 'purchaseDis'){ //采购折扣
			row.purchasePrice = (runNumber(runNumber(row.guidePrice) * runNumber(row[type]) / 100)).toFixed(2);
		}
		row.profitC = 0;  //实际利润
		//return row;
	}
	var salesDis = runNumber(row.salesDis),  //销售折扣
	purchaseDis = runNumber(runNumber(row.purchasePrice) / runNumber(row.guidePrice)) * 100,  //采购折扣
	profitSite = runNumber(row.profitSite), //利润点数
	guideSite = runNumber(row.guideSite), //面价点数
	salesPrice = runNumber(row.salesPrice),  //销售单价
	profitC = runNumber(row.profitC);  //实际利润
	if(type && type != 'purchasePrice'){
		if(isTypeNull(row[type])){return row};
		if(type === "guideSite"){ //面价点数
			guideSite = runNumber(row[type]);  //面价点数
			salesDis = purchaseDis + guideSite;   //销售折扣
			salesPrice = runNumber(salesDis / 100) * runNumber(row.guidePrice);  //销售单价
			profitC = runNumber(salesPrice - runNumber(row.purchasePrice));  //实际利润
			profitSite = runNumber(profitC / runNumber(row.purchasePrice)) * 100;  //利润点数
		}else if(type === "profitSite"){ //利润点数
			profitSite = runNumber(row[type]); //利润点数
			profitC = runNumber(profitSite / 100) * runNumber(row.purchasePrice);  //实际利润
			salesPrice = profitC + runNumber(row.purchasePrice);  //销售单价
			salesDis = runNumber(salesPrice / runNumber(row.guidePrice)) * 100;  //销售折扣
			guideSite = salesDis - purchaseDis;  //面价点数       
		}else if(type === 'purchaseDis'){ //采购折扣
			purchaseDis = runNumber(row[type]);  //采购折扣
			row.purchasePrice = (runNumber(runNumber(row.guidePrice) * purchaseDis / 100)).toFixed(2);
			salesDis = purchaseDis + guideSite; //销售折扣
			salesPrice = runNumber(salesDis / 100) * runNumber(row.guidePrice);  //销售单价
			profitC = salesPrice - runNumber(row.purchasePrice); //实际利润
			profitSite = runNumber(profitC / runNumber(row.purchasePrice)) * 100; //利润点数
		}else if(type === 'salesPrice'){ //销售价格
			salesPrice = runNumber(row[type]);  //销售价格
			profitC = salesPrice - runNumber(row.purchasePrice);  //实际利润
			profitSite = runNumber(profitC / runNumber(row.purchasePrice)) * 100; //利润点数
			salesDis = runNumber(salesPrice / runNumber(row.guidePrice)) * 100;  //销售折扣
			guideSite = salesDis - purchaseDis;  //面价点数
		}else if(type === 'salesDis'){ //销售折扣
			salesDis = runNumber(row[type]); //销售折扣
			salesPrice = runNumber(salesDis / 100) * runNumber(row.guidePrice);  //销售单价
			profitC = salesPrice - runNumber(row.purchasePrice);  //实际利润
			profitSite = runNumber(profitC / runNumber(row.purchasePrice)) * 100; //利润点数
			guideSite = salesDis - purchaseDis;  //面价点数
		}
	}else{
		if(salesPrice){ //销售价格
			if(type === 'purchasePrice'){
				purchaseDis = runNumber(runNumber(row[type]) / runNumber(row.guidePrice)) * 100;
				console.log(purchaseDis)
			}
			salesPrice = runNumber(row.salesPrice);  //销售价格
			profitC = salesPrice - runNumber(row.purchasePrice);  //实际利润
			profitSite = runNumber(profitC / runNumber(row.purchasePrice)) * 100; //利润点数
			salesDis = runNumber(salesPrice / runNumber(row.guidePrice)) * 100;  //销售折扣
			guideSite = salesDis - purchaseDis;  //面价点数
		}
	}
	row.purchaseDis = parseFloat(runNumber(purchaseDis.toFixed(2)));  //采购折扣
	row.salesDis = parseFloat(runNumber(salesDis.toFixed(2)));  //销售折扣
	row.salesPrice = parseFloat(runNumber(salesPrice.toFixed(2)));  //销售单价
	row.profitC = parseFloat(runNumber(profitC.toFixed(2)));  //实际利润
	row.profitSite = parseFloat(runNumber(profitSite.toFixed(2))); //利润点数
	row.guideSite = parseFloat(runNumber(guideSite.toFixed(2)));  //面价点数
	row.totalProfitC = (runNumber(row.profitC) * runNumber(row.numType ? row[row.numType] : row.qty)).toFixed(2);  //总价
	return row;
}