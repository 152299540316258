<template>
	<el-dialog  width="1400px" top="5vh">
		<div class="a">
			<el-tabs v-model="activeNav">
			    <el-tab-pane
					:label="item.title"
					:name="item.type"
					v-for="(item) in navs"
					:key="item.type"></el-tab-pane>
			</el-tabs>
			<div class="a-content">
				<div v-if="activeNav === 1">
					<no-account-number-form userType="0" :info="info" :data="data" :enterpriseInfo="enterpriseInfo"/>
				</div>
				<div v-else-if="activeNav === 2">
					<login-form :info="info" :data="data" :enterpriseInfo="enterpriseInfo"/>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import loginForm from "../loginForm.vue"
	import noAccountNumberForm from "../noAccountNumberForm.vue"
	export default{
		data(){
			return{
				navs:[
					{title:'已有平台账号',type:2},
					{title:'没有平台账号',type:1},
				],
				activeNav:2,
				loginInfo:{ //登录信息

				},
			}
		},
		props:['info','data','enterpriseInfo'],
		components:{
			loginForm,
			noAccountNumberForm,
		},
		methods:{
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
		},
  }
</script>

<style scoped lang="less">
	.a{
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding-bottom: 20px;
		}
		.a-content{
			padding-right: 20px;
			overflow-y: hidden;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
</style>
