<template>
  <!-- 报价批量操作 -->
  <div class="o">
    <div class="o-title">批量操作：</div>
    <div v-for="(item) in navs" :key="item.key">
      <div class="o-item" v-if="item.show">
        <span>{{ item.title }}:</span>
        <template v-if="item.type === 'date'">
          <el-date-picker
              v-model="obj[item.key]"
              type="date"
              placeholder="请选择"
              size="small"
              @change="(val) => {valChange(val,item)}"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type === 'select'">
          <el-select
              v-model="obj[item.key]"
              placeholder="请选择"
              size="small"
              @change="(val) => {valChange(val,item)}"
          >
            <el-option
                v-for="item in item.list"
                :key="item.title"
                :label="item.title"
                :value="item.title"
            ></el-option>
          </el-select>
        </template>
        <template v-else-if="item.type === 'area'">
          <el-cascader
              v-model="obj[item.key]"
              :options="item.list"
              size="small"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%;"
              @change="(val) => {valChange(val,item)}"
          ></el-cascader>
        </template>
        <template v-else>
          <el-input-number
              v-model="obj[item.key]"
              size="small"
              @change="(val) => {valChange(val,item)}"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {handleTreeData} from '@/common/js/common.js'
import area from "@/common/json/area.json"
import moment from 'moment'

export default {
  data() {
    return {
      obj: {
        manufactureDate: moment().format("YYYY-MM-DD"),
        purchaseDis: '',
        salesDis: '',
        channel: '',
        shipArea: [],
      },
      areaInfo: handleTreeData(area, 'name', 'name'),  //地区信息
    }
  },
  props: {
    isSimpleOffer: { //是否是简单版本
      default: true,
      type: Boolean,
    }
  },
  computed: {
    ...mapState('mysaleMudule', [
      'channel'
    ]),
    ...mapState([
      'position'
    ]),
    navs() {
      return [
        {title: '销售折扣', type: 'input', change: true, key: 'salesDis', show: true},
        {title: '采购折扣', type: 'input', change: true, key: 'purchaseDis', show: true},
        {title: '生产日期', type: 'date', key: 'manufactureDate', show: true},
        {title: '加点折扣', type: 'input', key: 'guideSite', change: true, show: !this.isSimpleOffer},
        {title: '利润点数', type: 'input', key: 'profitSite', change: true, show: !this.isSimpleOffer},
        {title: '渠道', type: 'select', key: 'channel', list: this.channel, show: true},
        {title: '地区', type: 'area', key: 'shipArea', list: this.areaInfo, show: true},

      ];
    }
  },
  methods: {
    valChange(val, row) {
      this.$emit('valChange', val, row.key, row.change);
    },
    setPosition() { //设置默认地址
      this.obj['shipArea'] = [...this.position];
      var row = this.navs.filter(v => v.key === 'shipArea')[0];
      this.$emit('valChange', this.obj['shipArea'], row.key, row.change);
    }
  },
  created() {
    if (this.position.length) {
      this.setPosition();
    }
  },
  watch: {
    '$store.state.position'(v) {
      this.position = v;
      this.setPosition();
    }
  }
}
</script>

<style lang="less" scoped>
.o {
  display: flex;
  align-items: center;
  padding: 10px 20px;

  .o-item {
    display: flex;
    align-items: center;
    margin-right: 15px;

    span {
      margin-right: 5px;
      white-space: nowrap;
    }
  }

  .o-title {
    white-space: nowrap;
  }
}
</style>
