<template>
	<div class="s" ref="bigBox">
		<div class="s-title" ref="sTitle">
			<div>
				<span class="s-max-name">盘雷</span>
				<span class="s-min-name">中国制造—工业供应链</span>
				<div class="s-info" style="display: inline-block;padding-left: 30px;">
					<el-row>询价公司:{{info?.inquiryRequestVO?.buyerEnterpriseName}}</el-row>
					<el-row>询价人:{{info?.inquiryRequestVO?.buyerName}}</el-row>
					<el-row>询价电话:{{info?.inquiryRequestVO?.buyerPhone}}</el-row>
				</div>
			</div>
			<div style="display: flex;align-items: center;">
				<div class="s-tips" style="display: flex;">
					<div class="tips-left" style="padding-right: 10px;">
						报价步骤:
					</div>
					<div class="tips-right">
						<el-row>一，填写价格，按确认报价</el-row>
						<el-row>二，微信扫码进行报价(没有账号注册账号)</el-row>
					</div>
				</div>
				<div class="switch" @click="isSimpleOffer = !isSimpleOffer">{{isSimpleOffer ? '复杂版' : '简单版'}}</div>
			</div>
		</div>
		<div class="s-content">
			<div class="s-m">
				<offerBatchOpera
					:isSimpleOffer="isSimpleOffer"
					@valChange="valChange" />
				<lida-table :pagination="false" :data="data" :height="HEIGHT" border ref="table">
					<!--            <lida-table-column label="序号" width="50">-->
					<!--              <template #default="scope">-->
					<!--                {{scope.row.id}}-->
					<!--              </template>-->
					<!--            </lida-table-column>-->
					<el-table-column type="index" label="序号" width="50" align="center" />
					<lida-table-column label="产品信息">
						<template #default="scope">
							<div class="g-info">
								<div class="g-info-right">
									<div class="g-info-row g-info-title">{{scope.row.productName}} |
										{{scope.row.seriesName}}
									</div>
									<div class="g-info-row">
										<span>参数:</span>
										<p>{{scope.row.barCode}}</p>
									</div>
									<div class="g-info-row">
										<span>订货号:</span>
										<p>{{scope.row.articleNo}}</p>
									</div>
									<div class="g-info-row">
										<el-tag>{{scope.row.brandName}}</el-tag>
									</div>
								</div>
							</div>
						</template>
					</lida-table-column>
					<lida-table-column label="数量" prop="qty" :min-width="150" align="center">
						<template #default="scope">
							<el-input-number v-model="scope.row.qty" :min="0" size="small" :disabled="!isOffer"
								@change="inputLv(scope.row)"/>
						</template>
					</lida-table-column>
					<el-table-column label="面价" prop="guidePrice" :min-width="150" align="center">
						<template #default="scope">
							<el-input v-model="scope.row.guidePrice" @input="inputLv(scope.row)" placeholder="请输入面价"
								:disabled="!isOffer" />
						</template>
					</el-table-column>



					<lida-table-column label="采购折扣(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
						<template #default="scope">
							<div class="t-row">
								<el-input-number v-model="scope.row.purchaseDis" size="small" :disabled="!isOffer"
									@change="inputLv(scope.row,'purchaseDis')" />
							</div>
						</template>
					</lida-table-column>


					<lida-table-column label="采购单价" :min-width="150" align="center" v-if="!isSimpleOffer">
						<template #default="scope">
							<el-input-number v-model="scope.row.purchasePrice" size="small" :disabled="!isOffer"
								@change="inputLv(scope.row,'purchasePrice')" />
						</template>
					</lida-table-column>

					<lida-table-column label="加点折扣" :min-width="150" align="center" class="redColor" v-if="!isSimpleOffer">
						<template #header="{column}">
							<span style="color:red">{{column.label}}</span>
						</template>
						<template #default="scope">
							<div class="t-row">
								<el-input-number v-model="scope.row.guideSite" size="small" :disabled="!isOffer"
									@change="inputLv(scope.row,'guideSite',)" />
							</div>
						</template>
					</lida-table-column>

					<lida-table-column label="利润点数(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
						<template #header="{column}">
							<span style="color:red">{{column.label}}</span>
						</template>
						<template #default="scope">
							<div class="t-row">
								<el-input-number v-model="scope.row.profitSite" size="small" :disabled="!isOffer"
									@change="inputLv(scope.row,'profitSite',)" />
							</div>
						</template>
					</lida-table-column>

          <lida-table-column label="销售折扣(%)" :min-width="150" align="center">
            <template #default="scope">
              <div class="t-row">
                <el-input-number v-model="scope.row.salesDis" size="small" :disabled="!isOffer"
                                 @change="inputLv(scope.row,'salesDis',)" />
              </div>
            </template>
          </lida-table-column>

          <lida-table-column label="销售单价" :min-width="150" align="center">
            <template #default="scope">
              <div class="t-row">
                <el-input-number v-model="scope.row.salesPrice" size="small" :disabled="!isOffer"
                                 @change="inputLv(scope.row,'salesPrice',)" />
              </div>
            </template>
          </lida-table-column>

					<!-- <lida-table-column label="询价数量" prop="skuQty" :min-width="140" align="center" /> -->

					<lida-table-column label="生产日期" :min-width="120" align="center">
						<template #default="scope">
							<el-date-picker v-model="scope.row.manufactureDate" type="date" placeholder="请选择"
								:disabled="!isOffer" size="small" :disabled-date="disabledDate">
							</el-date-picker>
						</template>
					</lida-table-column>

					<lida-table-column label="渠道" :min-width="120" align="center">
						<template #default="scope">
							<el-select v-model="scope.row.channel" placeholder="请选择" size="small" :disabled="!isOffer">
								<el-option v-for="item in channel" :key="item.title" :label="item.title"
									:value="item.title"></el-option>
							</el-select>
						</template>
					</lida-table-column>

					<lida-table-column label="地区" :min-width="120" align="center">
						<template #default="scope">
							<el-cascader v-model="scope.row.shipArea" :options="areaInfo" size="small"
								:props="{ expandTrigger: 'hover' }" style="width: 100%;" :disabled="!isOffer">
							</el-cascader>
						</template>
					</lida-table-column>

					<lida-table-column label="实际利润" fixed="right" :min-width="140" align="center" v-if="!isSimpleOffer">
						<template #default="scope">
							<div class="t-profit">
								<div class="t-profit-i">
									<span>实际利润:</span>
									<span>{{scope.row.profitC}}</span>
								</div>
							</div>
						</template>
					</lida-table-column>

					<lida-table-column label="总利润" fixed="right" :min-width="140" align="center" v-if="!isSimpleOffer">
						<template #default="scope">
							<div class="t-profit">
								<div class="t-profit-i">
									<span>总利润:</span>
									<span>{{scope.row.totalProfitC}}</span>
								</div>
							</div>
						</template>
					</lida-table-column>

					<lida-table-column label="操作" fixed="right">
						<template #default="scope">
							<el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
						</template>
					</lida-table-column>
				</lida-table>
				<div class="s-btn" ref="sBtn">
					<el-button type="primary" size="mini" @click="confirmOffer">确认报价</el-button>
				</div>
			</div>
		</div>
		<div class="s-footer" ref="sFooter">
			<div>
				<span class="s-max-name">盘雷</span>
				<span class="s-min-name">中国制造—工业供应链</span>
			</div>
			<el-row style="padding-top: 30px;">
				一链报价，报价记录，可追踪，可查看，促进买方找到卖方，卖方跟踪报价
			</el-row>
		</div>
	</div>
	<login-offer-dialog v-model="dialogShow" :info="info ? info.inquiryRequestVO : null" :data="list"
		:enterpriseInfo="enterpriseInfo" />
</template>

<script>
	import {
		mapActions,
		mapState,
		mapMutations
	} from 'vuex'
	import loginOfferDialog from '../components/dialog/loginOffer.vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import offerProfitCompute from '@/common/js/offerProfitCompute.js'
	import {
		handleTreeData
	} from '@/common/js/common.js'
	import offerBatchOpera from '@/components/publicComponent/offerBatchOpera.vue' //批量操作
	import area from "@/common/json/area.json"
	import moment from "moment"
	export default {
		data() {
			return {
				data: [], //商品列表
				dialogShow: false, //弹框的隐藏显示
				info: null,
				areaInfo: handleTreeData(area, 'name', 'name'), //地区信息
				list: [], //报价信息
				/** 根据分享人id查询到的企业信息 */
				enterpriseInfo: {},
				HEIGHT: '200', // 高度
				isSimpleOffer: true, //是否是简单报价(默认是)
			}
		},
		computed: {
			...mapState('mysaleMudule', [
				'channel'
			]),
			isOffer() { //判断是否能报价
				return this.info ?
					this.info.inquiryRequestVO &&
					(this.info.inquiryRequestVO.documentState === 'WAIT_QUOTE' || this.info.inquiryRequestVO
						.documentState === 'COMPLETE_QUOTE') ?
					true : false : false;
			},
			getParams() { //获取页面参数
				let {
					code,
					id,
					belongType,
					state
				} = this.$route.query;
				if (code) {
					id = state.split('$$$')[0];
					belongType = state.split('$$$')[1];
				}
				return {
					code,
					id,
					belongType
				}
			},
		},
		components: {
			loginOfferDialog,
			offerBatchOpera,
		},
		methods: {
			...mapActions('loginModule', [
				'shareInquiryShareInfo', //获取询价详情
				'uShareWechatLogin', //扫码登录 这个页面用这个
			]),
			...mapMutations(["renewToken", "renewUserInfo"]),
			...mapActions("purchaseMudule", [
				"quoteCreate" //新增报价
			]),
			...mapMutations('mysaleMudule', [
				'setOfferSuccessType',
			]),
			confirmOffer() { //确认报价
				if (this.isOffer) {
					var data = JSON.parse(JSON.stringify(this.data));
					var a = data.filter(v =>
						v.manufactureDate &&
						v.channel &&
						(v.purchasePrice || this.isSimpleOffer) &&
						(v.profitSite || this.isSimpleOffer) &&
						(v.guideSite || this.isSimpleOffer) &&
						v.shipArea
					);
					if (a.length) {
						for (var i = 0; i < a.length; i++) {
							a[i].shipArea = a[i].shipArea.join(',');
							a[i].manufactureDate = moment(a[i].manufactureDate).format('YYYY-MM-DD');
							a[i].skuId = a[i].skuId || a[i].id;
							if (this.info.sellerQuoteList && this.info.sellerQuoteList.length) {
								a[i].id = a[i].itemId;
							}
						}
						if (a.length != data.length) {
							ElMessageBox.confirm(
								'没填完报价信息的商品会自动过滤，是否继续报价?',
								'提示', {
									confirmButtonText: '确认',
									cancelButtonText: '取消',
									type: 'warning',
									center: true,
								}
							).then(() => {
								this.list = a;
								localStorage.setItem('shareOfferData',JSON.stringify(a));  //缓存本地数据
								this.dialogShow = true;
							}).catch(() => {})
						} else {
							this.list = a;
							localStorage.setItem('shareOfferData',JSON.stringify(a));  //缓存本地数据
							this.dialogShow = true;
						}
					} else {
						for (var i = 0; i < data.length; i++) {
							if (!data[i].manufactureDate) {
								return ElMessage({
									type: 'warning',
									message: '请选择生产日期',
								})
							} else if (!data[i].channel) {
								return ElMessage({
									type: 'warning',
									message: '请选择渠道',
								})
							} else if (!data[i].shipArea || !data[i].shipArea.length) {
								return ElMessage({
									type: 'warning',
									message: '请选择地区',
								})
							} else if (!data[i].purchasePrice && !this.isSimpleOffer) {
								return ElMessage({
									type: 'warning',
									message: '请填写采购单价',
								})
							} else if (!data[i].profitSite && !this.isSimpleOffer) {
								return ElMessage({
									type: 'warning',
									message: '请填写利润点数',
								})
							} else if (!data[i].guideSite && !this.isSimpleOffer) {
								return ElMessage({
									type: 'warning',
									message: '请填写面价点数',
								})
							}
						}
					}
				} else {
					if (this.getParams.id) {
						ElMessage({
							type: 'warning',
							message: '该询价已采购'
						})
					} else {
						ElMessage({
							type: 'warning',
							message: '请选择询价单'
						})
					}
				}
			},
			getDetails() { //获取询价详情
				this.shareInquiryShareInfo(this.getParams.id).then(res => {
					let {
						code,
						data
					} = res.data;
					if (code === '0') {
						data.itemRequestVOList.map(item => {
							item.qty = item.skuQty || 1; //数量
							item.manufactureDate = item.manufactureDate || moment().format(
								"YYYY-MM-DD"); //生产日期
							item.channel = item.channel || '现货'; //渠道
							item.purchasePrice = item.purchasePrice || ''; //采购单价
							item.profitSite = item.profitSite || ''; //利润点数
							item.guideSite = item.guideSite || ''; //面价点数
							item.guidePrice = (item.guidePrice || item.skuGuidePrice || 0).toFixed(2);
							item.shipArea = typeof item.shipArea === 'string' ? item.shipArea.split(',') :
								item.shipArea || [];
							item.numType = 'qty';
							return offerProfitCompute(item);
						})
						this.info = data;
						this.data = data.itemRequestVOList || [];

						//去报价
						let {code,id,belongType} = this.getParams;
						if (code) {
							this.uShareWechatLogin({code, id, belongType}).then(res => {
								let {
									code,
									data,
									msg
								} = res.data;
								if (code === "0" && data.token) {
									//登录成功
									this.renewToken(res.data.data.token); //更新token
									this.renewUserInfo(res.data.data);
									if (this.info) {
										//登录并报价
										if (data.userType === "0") {
											//报价
											this.goOffer();
										} else {
											ElMessageBox.confirm(
													"当前登录的账号是普通账号不能参与报价，是否跳转到首页?",
													"提示", {
														confirmButtonText: "确认",
														cancelButtonText: "取消",
														type: "warning"
													}
												)
												.then(() => {
													this.jumpPage(); //跳转到登录首页
												})
												.catch(() => {});
										}
									} else {
										//直接登录
										//跳转页面
										this.jumpPage();
									}
								} else {
									ElMessage({
										showClose: true,
										message: msg || data.msg,
										type: "error"
									});
								}
							})
						}

					} else {
						this.info = null;
					}
				})
			},
			inputLv(row, type, linkage) { //监听面价点数和利润点数输入的事件
				row = offerProfitCompute(row, type, linkage);
			},
			deleteRow(obj) { //删除商品的事件
				this.data.splice(obj.$index, 1);
			},
			// 设置表格高度
			getHeight() {
				this.$nextTick(() => {
					this.HEIGHT = this.$refs.bigBox.offsetHeight - (this.$refs.sFooter.offsetHeight + this.$refs
						.sTitle.offsetHeight + this.$refs.sBtn.offsetHeight + 50)
				})
			},
			valChange(val, key, isCompute) {
				this.data.map(item => {
					item[key] = val;
					if (isCompute) {
						item = this.inputLv(item, key);
					}
					return item;
				})
			},
			jumpPage() {
				this.setOfferSuccessType(true);
				this.$router.push({
					path: "/home/index"
				});
			},
			goOffer() {
				var data = localStorage.getItem('shareOfferData');
				if(data){
					data = JSON.parse(data);
					//报价
					this.quoteCreate({
						itemRequestVOList: data,
						quoteRequestVO: {
							inquiryId: this.info.inquiryRequestVO.id,
							buyerEnterpriseId: this.info.inquiryRequestVO.buyerEnterpriseId
						}
					}).then(res => {
						let {
							data,
							code
						} = res.data;
						if (code === "0") {
							ElMessage({
								type: "success",
								message: "报价成功"
							});
							this.$emit("bjSuccess");
							this.setOfferSuccessType(true);
							//跳转到我的报价页面
							this.$router.push({
								path: "/mysale/customerOffer"
							});
							sessionStorage.setItem('shareOfferData',null);  //清空本地报价数据
						}
					});
				}
			},
		},
		created() {
			this.getDetails();
		},
		mounted() {
			this.getHeight()
			window.onresize = () => {
				this.getHeight()
			}
		},
		unmounted() {
			window.onresize = false
		}
	}
</script>

<style scoped lang="less">
	.s {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.s-content {
			flex: 1;
			overflow: hidden;

			.s-m {
				overflow-y: auto;
			}
		}

		background: #ffffff;

		.s-title,
		.s-footer {
			background: #314150;
			height: 80px;
			box-sizing: border-box;
			padding: 0 100px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.s-max-name {
				font-size: 40px;
			}

			.s-min-name {
				margin-left: 10px;
			}
		}

		.s-title {
			justify-content: space-between;
		}

		.s-btn {
			display: flex;
			justify-content: center;
			padding: 10px 0;
		}
	}

	.labelClassName {
		color: red;
	}

	.g-info-right {
		font-size: 12px;

		.g-info-row {
			display: flex;
			align-items: center;
			color: #666666;

			span {
				min-width: 50px;
			}

			p {
				flex: 1;
			}
		}
	}
	.switch{
		width: 60px;
		height: 30px;
		border-radius: 4px;
		background: #ffffff;
		position: relative;
		cursor: pointer;
		color: @dh-color;
		text-align: center;
		line-height: 30px;
		left: 20px;
	}
	.switch::after{
		position: absolute;
		content: '';
		width: 0px;
		height: 0px;
		border-bottom: 15px solid transparent;
		border-left: 15px solid #ffffff;
		border-top: 15px solid transparent;
		right: -13px;
	}
</style>
